<template>
    <div>
        <!-- PAYMENT !-->
        
        <!-- SUCCESS -->
        <div>
            <div class="d-flex align-items-center justify-content-center flex-column success__container">
                <img src="../assets/icons/success.svg">
                <h5 class="text-uppercase py-3">{{$store.state.translation.payment_result_title}}</h5>
                <p>{{$store.state.translation.pasment_result_subtitle}}</p>
                <p >{{$store.state.translation.payment_id}}: {{$route.query.payment_intent}}</p>

                <a href="/" class="link px-3 py-2 text-uppercase text-center text-white text-decoration-none mt-5">{{$store.state.translation.back_to_home}}</a>
            </div>
        </div>
    </div>
</template>

<script>


export default {
   
    created(){
        this.post('ordercontroller/payment_started',{order_id:this.$route.params.order_id}).catch(()=>{})
         
    }
}
</script>